var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-dialog',{attrs:{"width":"700","transition":"dialog-bottom-transition"},model:{value:(_vm.dateRangeDialog),callback:function ($$v) {_vm.dateRangeDialog=$$v},expression:"dateRangeDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('h3',[_vm._v("دیاریکردی ماوە")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.search}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" گەڕان ")],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-form',{ref:"searchForm"},[_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"لە بەرواری","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","rules":_vm.rules.date},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"بۆ بەرواری","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","rules":_vm.rules.date},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.debtors},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"dense":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.view(item.customer_id)}}},[_c('v-icon',[_vm._v("mdi-eye-outline")]),_vm._v(" بینین ")],1)]}},{key:"item.debt",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.debt.toFixed(2).toLocaleString())+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }