<template>
    <v-container>
        <v-row>
            <v-dialog v-model="dateRangeDialog" width="700" transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar color="primary" dark>
                        <h3>دیاریکردی ماوە</h3>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn text @click="search">
                                <v-icon>mdi-magnify</v-icon>
                                گەڕان
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-container fluid>
                        <v-form ref="searchForm">
                            <v-row>
                                <v-col>
                                    <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field @focus="$event.target.select()" v-model="from" label="لە بەرواری"
                                                prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined
                                                dense :rules="rules.date"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="from" @input="menu1 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field @focus="$event.target.select()" v-model="to" label="بۆ بەرواری"
                                                prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined
                                                dense :rules="rules.date"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="to" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table :headers="headers" :items="debtors">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn dense color="primary" outlined @click="view(item.customer_id)">
                            <v-icon>mdi-eye-outline</v-icon>
                            بینین
                        </v-btn>
                    </template>
                    <template v-slot:[`item.debt`]="{ item }">
                        ${{ item.debt.toFixed(2).toLocaleString() }}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            debtors: [],
            menu1: false,
            menu2: false,
            from: null,
            to: null,
            customer_id: 0,
            dateRangeDialog: false,
            rules: {
                date: [
                    v => !!v || 'بەروارێک هەڵبژێرە',
                    v => (this.from && this.to ? new Date(this.from) <= new Date(this.to) || 'بەرواری سەرەتا پێویستە بچووکتربێت لە بەرواری کۆتا' : true)]
            },
            headers: [
                { text: 'ناوی کڕیار', value: 'customer_name', align: 'center' },
                { text: 'قەرز', value: 'debt', align: 'center', sortable: true },
                { text: 'ژمارەی مۆبایل', value: 'mobile', align: 'center' },
                { text: 'کردارەکان ', value: 'actions', align: 'center' },
            ]
        }
    },
    methods: {
        async getdebtors() {
            const { data } = await this.axios.get('/report/debtors')
            this.debtors = data
        },
        view(customer_id) {
            this.customer_id = customer_id
            this.dateRangeDialog = true
        },
        search() {
            if (this.$refs.searchForm.validate()) {
                window.open('/report/customer/' + this.customer_id + '/' + this.from + '/' + this.to, '_blank')
            }

        },
    },
    created() {
        this.getdebtors()
    },
}
</script>